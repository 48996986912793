import * as React from "react";
import { NavMenu } from "../buttons/NavMenu";

export interface HeaderProps {
  title: string;
  logo: string;
}

const Header: React.FunctionComponent<HeaderProps> = (props) => {
  const { title, logo } = props
  
  return(
    <header className="ms-welcome__header wrwx-header" style={{position: "sticky", zIndex: 1, top: "0px"}}>
      <h1 className={chooseFontSizeByLength(title.length)}>{title}</h1>
      <img id="wrwx-icon" src={logo} title={title}/>
      <NavMenu></NavMenu>
    </header>
  );
}

export default Header;

function chooseFontSizeByLength(len: number){
  switch (true) {
    case (len >= 10):
      return 'ms-font-xxl';
    default:
      return 'ms-font-su';
  }
}
import * as React from "react";
import { useEffect } from 'react';
import { PrimaryButton } from "@fluentui/react";


const FirstRun = () => {
  
  useEffect(() => {}, []); // is this needed?
  // the previous last class component had an empty componentDidMount() {}
  
  const click = () => {
    localStorage.setItem('wrwx/wrangles/shownFirstRun', 'true');
    location.reload();
  }
  
  return(
    <div className="ms-welcome">
      <section className="ms-welcome__header ms-bgColor-neutralLighter ms-u-fadeIn500">
        <br/>
        <img width="220" src={"/assets/logos/wrangleworks-220x36.png"} />
        <br/>
      </section>
      <br/>
      <main className="ms-welcome__main">
        <h2 className="ms-font-xl ms-fontWeight-semilight ms-fontColor-neutralPrimary ms-u-slideUpIn20">
        ‘Wrangles’ are a range of easy but powerful transformations for data cleaning and enrichment.
        </h2>
        <br/>
        <ul style={{width:'98%'}} className="ms-List ms-welcome__features ms-u-slideUpIn10">
          <li className="ms-ListItem">
            <i className={"ms-Icon ms-Icon--D365TalentLearn"}></i>
            <span className="ms-font-m ms-fontColor-neutralPrimary">Go to <a href='/help.html'><b>How To Wrangle</b></a> to learn more.</span>
          </li>
          <li className="ms-ListItem">
            <i className={"ms-Icon ms-Icon--LightningBolt"}></i>
            <span className="ms-font-m ms-fontColor-neutralPrimary">Use our range of stock wrangles to <b>Extract</b>, <b>Format</b>, <b>Classify</b> or <b>Translate</b> your data.</span>
          </li>
          <li className="ms-ListItem">
            <i className={"ms-Icon ms-Icon--WebAppBuilderFragment"}></i>
            <span className="ms-font-m ms-fontColor-neutralPrimary">Train and manage your own Wrangles on the <b>My Wrangles</b> task pane.</span>
          </li>
        </ul>
        <p style={{textAlign: "center", width: '98%'}} className="ms-font-l ms-fontWeight-semilight ms-fontColor-neutralPrimary">
          Click <b>Get Started</b> to register or log in, then get Wrangling!
        </p>
        <br/>
        <br/>
        <PrimaryButton className="ms-welcome__action" onClick={click}>
          Get Started
        </PrimaryButton>
      </main>
    </div>
  );
  
}

export default FirstRun;
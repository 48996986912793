import * as React from 'react';
import Keycloak from "keycloak-js";
import { useBoolean } from '@fluentui/react-hooks';

export const ApiContext = React.createContext(null);

export interface WranglesProviderProps {
  /** Child components to render */
  children: React.ReactNode;
  /** Keycloak Init Options */
  init: Keycloak.KeycloakInitOptions;
  /** Keycloak configuration options, url, realm, clientID etc.  */
  config: Keycloak.KeycloakConfig;
  /** A placeholder to show while the user is being authenticated */
  placeholder: React.JSX.Element
}

/**
 * Api Provider
 */
export const WranglesProvider: React.FunctionComponent<WranglesProviderProps> = ({ children, init, config, placeholder }) => {
    const wrangles = React.useRef(new Wrangles(config, "https://api.wrangle.works"));
    const [loading, { setFalse: finishedLoading }] = useBoolean(true);
    
    React.useEffect(() => {
      async function initializeKeycloak(){
        const isAuthenticated = await wrangles.current._initializeKeycloak(init);
        if (isAuthenticated) {
            finishedLoading();
        }
      }
      initializeKeycloak();
    }, []);

    return (
        loading
          ? placeholder
          : <ApiContext.Provider value={wrangles}>
              {children}
            </ApiContext.Provider>
    );
};

/**
 * Wrangles API Hook
 * @returns 
 */
export const useWrangles = () => {
  const wrangles = React.useContext(ApiContext);
  return wrangles.current as Wrangles;
}

/**
 * Wrangles API Class
 */
export class Wrangles {
  keycloak: Keycloak.KeycloakInstance;
  apiHost: string;
  organizations: Array<IOrganization>;
  organizationMembers: Array<IUser>;

  constructor(config: Keycloak.KeycloakConfig, apiHost: string){
    this.keycloak = new Keycloak(config);
    this.apiHost = apiHost

    // Bind all methods to the instance
    this._bindMethods();
  }

  _bindMethods() {
    // Get all property names of the prototype
    const propertyNames = Object.getOwnPropertyNames(Object.getPrototypeOf(this));
    
    // Bind each method to the instance
    propertyNames.forEach(name => {
        if (typeof this[name] === 'function' && name !== 'constructor') {
            this[name] = this[name].bind(this);
        }
    });
  }

  /**
   * Initialse keycloak and authenticate the user
   * @param init Keycloak init options
   * @returns True or False to indicate if the user was successfully authenticated
   */
  async _initializeKeycloak(init: Keycloak.KeycloakInitOptions) {
    const isAuthenticated = await this.keycloak.init(init);
    return isAuthenticated;
  }

  /**
   * Returns a parsed version of the user's token
   * @returns Parsed token object
   */
  token(){
    return this.keycloak.tokenParsed
  }

  //
  // MODELS
  //
  /**
   * Get a list of models belonging to a user
   * @param purpose (Optional) If provided, will only get models of a provided type
   * @returns 
   */
  public async getUserModels(purpose?: string) {
    return await this.keycloak.updateToken(30).then(async () => {
      const url = new URL(`${this.apiHost}/user/models`);
      const params = {};

      // If model_id specified, pass that on
      if (typeof purpose !== 'undefined'){
        params['type'] = purpose
      }

      url.search = new URLSearchParams(params).toString();

      return fetch(url.toString(), {
        credentials: 'include',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + this.keycloak.token
        }
      })
      .then(response => { 
        if (!response.ok){ throw response.status };
        return response
      })
      .then(response => {
        return response.json()
      })
    })
  }

  /**
   * Get a list of old versions for a specific model
   * @param model_id ID of the model
   * @returns 
   */
  async getModelVersions(model_id?: string) {
    return await this.keycloak.updateToken(30).then(async () => {
      const url = new URL(`${this.apiHost}/model/content/versions`);
      const params = {'model_id': model_id};

      url.search = new URLSearchParams(params).toString();

      return fetch(url.toString(), {
          credentials: 'include',
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + this.keycloak.token
          }
      })
      .then(response => { 
        if (!response.ok){ throw response.status };
        return response
      })
      .then(response => {
        return response.json()
      })
    })
  }


  /**
   * Execute a recipe
   * @param model_id The ID of the recipe to run
   * @param data Any data to send
   * @param variables A dictionary of variables to send
   * @param recipe (Optional) Overwrite the stored recipe
   * @param functions (Optional) Overwrite the stored functions
   * @returns 
   */
  async executeRecipe(
    model_id: string,
    data: any[][],
    variables: {},
    recipe?: string,
    functions?: string
  ) {
    try{
      return await this.keycloak.updateToken(30).then(async () => {
        const url = new URL(`${this.apiHost}/recipe/run`);
        const body = {
          model_id: model_id,
          data: data,
          variables: variables
        }
        if (recipe){ body["recipe"] = recipe }
        if (functions){ body["functions"] = functions }

        //get the body before sending it out to see size
        const body_json = JSON.stringify(body);
        
        //adding it all together
        const encoder = new TextEncoder(); 
        const bytes = encoder.encode(body_json).length;
        //determine the size limit. Current size limit ~ 5353386
        if (bytes > 5200000) {
          throw new Error('Size limit exceeded')
        }
        
        return await fetch(url.toString(), {
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + this.keycloak.token
          },
          body: body_json
        })
      })    
    } catch (e){
      return e
    }
  }

  //
  // SECRETS
  //
  /**
   * Get secret keys. This does not disclose the secrets themselves.
   * @param model_id (Optional) If provided, will get secrets for a model, otherwise the user. 
   * @returns 
   */
  async getSecretKeys(model_id?: string) {
    return await this.keycloak.updateToken(30).then(async () => {
      const url = new URL(`${this.apiHost}/user/secret/keys`);
      const params = {};

      // If model_id specified, pass that on
      if (typeof model_id !== 'undefined'){
        params['model_id'] = model_id
      }

      url.search = new URLSearchParams(params).toString();

      return fetch(url.toString(), {
          credentials: 'include',
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + this.keycloak.token
          }
      })
      .then(response => { 
        if (!response.ok){ throw response.status };
        return response
      })
      .then(response => {
        return response.json()
      })
    })
  }

  /**
   * Create or update a secret
   * @param key Key
   * @param secret Secret value
   * @param model_id (Optional) If specified, associates the secret with a model instead of the user
   */
  async putSecret(key: string, secret: string, model_id?: string) {
    await this.keycloak.updateToken(30).then(async () => {
      const url = new URL(`${this.apiHost}/user/secret`);
      const params = {};

      // If model_id specified, pass that on
      if (typeof model_id !== 'undefined'){
        params['model_id'] = model_id
      }

      url.search = new URLSearchParams(params).toString();

      fetch(url.toString(), {
          credentials: 'include',
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + this.keycloak.token
          },
          body: JSON.stringify(
            {
              key: key,
              secret: secret
            }
          ),
      })
      .then(response => { 
        if (!response.ok){ throw response.status } 
      })
    })
  }

  /**
   * Delete a secret
   * @param key The secret's key
   * @param model_id (Optional) If specified, deletes a secret associated with a model instead of the user
   */
  async deleteSecret(key: string, model_id?: string) {
    await this.keycloak.updateToken(30).then(async () => {
      const url = new URL(`${this.apiHost}/user/secret`);
      const params = {'key': key};

      // If model_id specified, pass that on
      if (typeof model_id !== 'undefined'){
        params['model_id'] = model_id
      }

      url.search = new URLSearchParams(params).toString();

      fetch(url.toString(), {
          credentials: 'include',
          method: 'DELETE',
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + this.keycloak.token
          },
      })
      .then(response => { 
        if (!response.ok){ throw response.status } 
      })
    })

  }

  //
  // MODEL / ACCESS
  //
  /**
   * Get a list of users who have access to a model
   * @param model_id The model ID
   * @returns An array of objects with user details
   */
  async getModelAccess(model_id: string) {
    return await this.keycloak.updateToken(30).then(async () => {
      const url = new URL(`${this.apiHost}/model/access`);
      const params = { model_id: model_id };
      url.search = new URLSearchParams(params).toString();

      return fetch(url.toString(), {
          credentials: 'include',
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + this.keycloak.token
          }
      })
      .then(response => { 
        if (!response.ok){ throw response.status };
        return response
      })
      .then(response => {
        return response.json()
      })
    })
  }

  /**
   * Update the role for a user against a particular model
   * @param model_id The model's ID
   * @param user_id The user's ID
   * @param role The new role to set
   * @returns 
   */
  async putModelAccess(model_id: string, user_id: string, role: string) {
    return await this.keycloak.updateToken(30).then(async () => {
      const url = new URL(`${this.apiHost}/model/access`);
      const params = {
        model_id: model_id,
        user_id: user_id,
        role: role
      };
      url.search = new URLSearchParams(params).toString();

      return fetch(url.toString(), {
          credentials: 'include',
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + this.keycloak.token
          }
      })
      .then(response => { 
        if (!response.ok){ throw response.status };
        return response
      })
      .then(response => {
        return response.json()
      })
    })
  }

  async postModelAccess(
    model_id: string,
    email: string,
    role: Role = "user"
  ) {
    return await this.keycloak.updateToken(30).then(async () => {
      const url = new URL(`${this.apiHost}/model/access`);
      const params = {
        model_id: model_id,
        email: email,
        role: role
      };
      url.search = new URLSearchParams(params).toString();

      return fetch(url.toString(), {
          credentials: 'include',
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + this.keycloak.token
          }
      })
    })
  }

  /**
   * Remove a user's access to a model
   * @param model_id Model to remove access to
   * @param user_id User who will be removed
   * @returns fetch response
   */
  async deleteModelAccess(model_id: string, user_id: string) {
    return await this.keycloak.updateToken(30).then(async () => {
      const url = new URL(`${this.apiHost}/model/access`);
      const params = {
        model_id: model_id,
        user_id: user_id
      };
      url.search = new URLSearchParams(params).toString();

      return fetch(url.toString(), {
          credentials: 'include',
          method: 'DELETE',
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + this.keycloak.token
          }
      })
    })
  }


  //
  // MODEL / METADATA
  //
  /**
   * Get the metadata for a specific model
   * @param id Model ID
   * @returns Dictionary of metadata, including name, tags, notes etc.
   */
  public async getModelMetadata(id: string) {
    return await this.keycloak.updateToken(30).then(async () => {
      const url = new URL(`${this.apiHost}/model/metadata`);
      const params = { id: id };
      url.search = new URLSearchParams(params).toString();
      return fetch(url.toString(), {
        credentials: 'include',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this.keycloak.token
        }
      })
      .then(response => {
        if (!response.ok) { throw response.status };
        return response
      })
      .then(response => {
        return response.json();
      })
    });
  };

  /**
   * Update a model's metadata.
   * This will patch the data - only changing the provided keys.
   * @param model_id The ID to be updated
   * @param data A dictionary of metadata keys and values
   */
  async patchModelMetadata(model_id: string, data: object) {
    await this.keycloak.updateToken(30).then(async () => {
      var url = new URL(`${this.apiHost}/model/metadata`);
      var params = { id: model_id };
      url.search = new URLSearchParams(params).toString();

      fetch(url.toString(), {
        credentials: 'include',
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this.keycloak.token
        },
        body: JSON.stringify(data),
      })
      .then(response => { 
        if (!response.ok){ throw response.status };
      })
    })

  }

  //
  // MODEL / CONTENT
  //
  /**
   * Get training data for a specific Wrangle
   * @param model_id
   * @returns
   */
  async getModelContent(
    model_id: string,
    version_id?: string
  ) {
    return await this.keycloak.updateToken(30).then(async () => {
      const url = new URL(`${this.apiHost}/model/content`);
      const params = { model_id: model_id };
      // If a version_id is specified, pass that on
      if (typeof version_id !== 'undefined'){
        params['version_id'] = version_id
      }
      url.search = new URLSearchParams(params).toString();

      const data = await fetch(url.toString(), {
        credentials: 'include',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' +this.keycloak.token
        },
      })
      .then(response => { 
        if (!response.ok){ throw response.status }
        return response
      })
      .then(response => response.json());

      return data
    })
  }

  /**
   * Update (and overwrite) a Wrangle's training data
   * @param model_id Wrangle ID
   * @param data New training data
   */
  async putModelContent(
    model_id: string,
    data: any,
    settings: object = {}
  ) {
    await this.keycloak.updateToken(30).then(async () => {
      const url = new URL(`${this.apiHost}/model/content`);
      const params = { ...settings, model_id: model_id };
      url.search = new URLSearchParams(params).toString();

      // Get the body before sending it out to check size
      const body_json = JSON.stringify(data);

      // Determine the size limit. Current size limit 10MB
      if (new TextEncoder().encode(body_json).length > 10000000) {
        throw new Error('Size limit exceeded')
      }

      await fetch(url.toString(), {
        credentials: 'include',
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this.keycloak.token
        },
        body: body_json,
      })
      .then(response => {
        if (!response.ok){ throw response.status } 
      })
    })
  }

  /**
   * Create a new Wrangle
   * @param purpose classify, extract, standardize etc.
   * @param name Name of the new wrangle
   * @param data Training Data
   */
  async postModelContent(purpose: string, name: string, data: any,  settings:Record<string, string> = {}) {
    await this.keycloak.updateToken(30).then(async () => {
      const url = new URL(`${this.apiHost}/model/content`);
      const params = { type: purpose, name: name,  ...settings };
      url.search = new URLSearchParams(params).toString();

      fetch(url.toString(), {
          credentials: 'include',
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + this.keycloak.token
          },
          body: JSON.stringify(data),
      })
      .then(response => { 
        if (!response.ok){ throw response.status } 
      })
    })
  }

  //
  // MODEL / PREFERENCES
  //
  /**
   * Set user specific preferences for a model
   * @param keycloak Keycloak instance for API Auth
   * @param model_id Model ID to set the preferences for
   * @param preferences Object of preference keys and values
   */
  async patchModelPreferences(model_id: string, preferences: object) {
    await this.keycloak.updateToken(30).then(async () => {
      var url = new URL(`${this.apiHost}/model/preferences`);
      url.search = new URLSearchParams({ model_id: model_id }).toString();

      fetch(url.toString(), {
        credentials: 'include',
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this.keycloak.token
        },
        body: JSON.stringify(preferences),
      })
      .then(response => { 
        if (!response.ok){ throw response.status };
      })
    })
  }

  //
  // WRANGLES
  //
  /**
   * Run a Classification wrangle
   * @param data A 1D array of data to be classified
   * @param model_id The model ID to execute
   * @returns A 1D array of classifications corresponding to the input data
   */
  async classify(data: any[], model_id: string) {
    return await this.keycloak.updateToken(30).then(async () => {        
      // Make API Call
      const url = new URL(`${this.apiHost}/wrangles/classify`);
      const params = { model_id: model_id }
      url.search = new URLSearchParams(params).toString();

      return fetch(url.toString(), {
          credentials: 'include',
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + this.keycloak.token
          },
          body: JSON.stringify(data),
      })
    })
    .then(response => response.json())
  }

  async extractAddress(data: any[], dataType: string) {
    return await this.keycloak.updateToken(30).then(async () => {        
      const url = new URL(`${this.apiHost}/wrangles/extract/address`);
      const params = {
        responseFormat: "array",
        responseContent: "span",
        dataType: dataType
      }
      url.search = new URLSearchParams(params).toString();
      return fetch(url.toString(), {
          credentials: 'include',
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + this.keycloak.token
          },
          body: JSON.stringify(data),
      })
    })
    .then(response => response.json())
  }
  
  async extractAttributes(data: any[], dataType: string) {
    return await this.keycloak.updateToken(30).then(async () => {        
      const url = new URL(`${this.apiHost}/wrangles/extract/attributes`);
      const params = { responseFormat: "array", responseContent: "span" }
      if (dataType != "all"){
        params['attributeType'] = dataType
      }
      url.search = new URLSearchParams(params).toString();
      return fetch(url.toString(), {
          credentials: 'include',
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + this.keycloak.token
          },
          body: JSON.stringify(data),
      })
    })
    .then(response => response.json())
  }

  async extractCodes(data: any[]) {
    return await this.keycloak.updateToken(30).then(async () => {        
      const url = new URL(`${this.apiHost}/wrangles/extract/codes`);
      url.search = new URLSearchParams({ responseFormat: 'array' }).toString();
      return fetch(url.toString(), {
          credentials: 'include',
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' +this.keycloak.token
          },
          body: JSON.stringify(data),
      })
    })
    .then(response => response.json())
  }

  async stripBrackets(array: string[]){
    const stripChars = arrayRow => arrayRow.substring(1, arrayRow.length - 1);
    const results = array.map(stripChars);
    return results
  }

  async extractQuotes(data: any[], dataType: string) {
    var quoteType = {
      'singleQuotes': /'[aA-zZ0-9]+'/g,
      'doubleQuotes': /"[aA-zZ0-9]+"/g
    }
    
    var results = [];
    for (var j = 0; j < data.length; j++) {
      //find the desired regex
      var quote = data[j].match(quoteType[dataType]);
      
      //Safely concatenate into an array
      var result = [];
      var result = result.concat(quote || []);
      result = await this.stripBrackets(result)
      results = results.concat([result]);
    }
    
    return results;
  }

  /**
   * Extract the contents of any brackets
   * @param data 1D array of strings to extract the contents of
   * @param dataType Specific brackets
   * @returns 
   */
  async extractBrackets(
    data: any[],
    dataType: string,
    includeBrackets: boolean,
  ) {
    // regex object
    var bracket_type = {
      'Curly': /\{(.+?)\}/g,
      'Square': /\[(.+?)\]/g,
      'Round': /\((.+?)\)/g,
      'Angled': /\<(.+?)\>/g,
    }
    
    var results = [];
    if (dataType == 'All') {
      for (var j = 0; j < data.length; j++) {
        var angled = data[j].match(/\<(.+?)\>/g);
        var curly = data[j].match(/\{(.+?)\}/g);
        var round = data[j].match(/\((.+?)\)/g);
        var square = data[j].match(/\[(.+?)\]/g);
  
        var result = [];
        result = result.concat(angled || [], curly || [], round || [], square || []);
        result = await this._removeBrackets(result, includeBrackets) // Pass includeBrackets
        results = results.concat([result]);
      }
    } else {
      for (var j = 0; j < data.length; j++) {
        var bracket = data[j].match(bracket_type[dataType]);
  
        var result = [];
        result = result.concat(bracket || []);
        result = await this._removeBrackets(result, includeBrackets) // Pass includeBrackets
        results = results.concat([result]);
      }
    }
    return results;
  };
  
  // Remove or keep the brackets based on the includeBrackets parameter
  async _removeBrackets(matches: string[], includeBrackets: boolean) {
    if (includeBrackets) {
      // Return matches as is if includeBrackets is true
      return matches;
    } else {
      // Existing logic to strip brackets
      // For example, removing the first and last character of each match
      return matches.map(match => match.substring(1, match.length - 1));
    }
  }
  

  async extractCustom(
    data: any[],
    model_id: string,
    useLabels: string,
    caseSensitive: string,
    spellCheck: string,
    extractRaw: string
  ) {
    return await this.keycloak.updateToken(30).then(async () => {        
      const url = new URL(`${this.apiHost}/wrangles/extract/custom`);
      const params = {
        responseFormat: 'array',
        model_id: model_id,
        use_labels: useLabels,
        caseSensitive: caseSensitive,
        use_spellcheck: spellCheck,
        extract_raw: extractRaw
      }
      url.search = new URLSearchParams(params).toString();
      return fetch(url.toString(), {
          credentials: 'include',
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + this.keycloak.token
          },
          body: JSON.stringify(data),
      })
      .then(response => { 
        if (!response.ok){ throw response.status };
        return response
      })
      .then(response => {
        return response.json()
      })
    })
  }

  async extractProperties(data: any[], dataType: string) {
    return await this.keycloak.updateToken(30).then(async () => {        
      const url = new URL(`${this.apiHost}/wrangles/extract/properties`);
      const params = { responseFormat: "array" }
      if (dataType != "all"){
        params['dataType'] = dataType
      }
      url.search = new URLSearchParams(params).toString();
      return fetch(url.toString(), {
          credentials: 'include',
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' +this.keycloak.token
          },
          body: JSON.stringify(data),
      })
    })
    .then(response => response.json())
  }

  async standardize(data: any[], model_id: string) {
    return await this.keycloak.updateToken(30).then(async () => {        
      // Make API Call
      const url = new URL(`${this.apiHost}/wrangles/standardize`);
      const params = {
        responseFormat: 'array',
        model_id: model_id
      }
      url.search = new URLSearchParams(params).toString();

      return fetch(url.toString(), {
          credentials: 'include',
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' +this.keycloak.token
          },
          body: JSON.stringify(data),
      })
    })
    .then(response => response.json())
  }

  /**
   * Standardize Phone numbers based on the output
   * @param data 2D Array of phone numbers to standardize
   * @param phoneFormat The format to standardize to
   */
  async standardizePhoneNumber(data: any[][], phoneFormat: string) {
    return data.map((row) => {
      return row.map((cell) => {
        let inputValue = String(cell); // Convert to a string to ensure it can be manipulated  
        let result: string;
        if (inputValue == "") {
          result = inputValue;
        } else {
          var replaceNonDigits = inputValue.replaceAll(/\D+/g, "");
          if (replaceNonDigits == "") {
            result = "";
          } else {
            var numberReverse = replaceNonDigits.split("").reverse().join("");
            var splitPhoneFormat = phoneFormat.split("").reverse();
            try {
              //iterate right to left and replace X's with the digits
              var count = 0;
              var newArr = []
              for (var i = 0; i < phoneFormat.length; i++) {
                if (count > numberReverse.length) { break; }
                if (splitPhoneFormat[i] == 'X') {
                  newArr.push(numberReverse[count])
                  count++;
                } else {
                  newArr.push(splitPhoneFormat[i])
                }
              }
              var replacementValue = newArr.slice().reverse().join("");
              result = replacementValue;
            } catch {
              result = inputValue;
            }
          }
        }
        return result;
      });
    });
  };

  /**
 * Convert and replace all fractions found in a string to decimal
 * @param data 2D Array of fractions to convert
 * @param decimalPlaces Number of decimal places to round to
 * @returns Converted string
 */
  async standardizeFractionsToDecimals(data: any[][], decimalPlaces: string) {
    return data.map((row) => {
      return row.map((cell) => {
        if (typeof cell == 'number') { //This number is already a decimal
          //Excel does the conversion to decimal so we only need to round to the specified decimal places
          //Excel may return this as a type fraction so conversion may seem that it is not working
          //Use Excel's type number to convert to fractions
          return parseFloat(cell.toFixed(Number(decimalPlaces)));
        } else {
          let input = String(cell);
          // Regular expression to match fractions in the format "n/m"
          const places = Number(decimalPlaces);
          const fractionRegex = /(\d+[\s-])?(\d+)\/(\d+)/g;
          // Array to store the decimal equivalents of the fractions
          let decimalValues = [];
          // Replace all fractions in the input string with their decimal equivalents
    
          const output = input.replace(fractionRegex, (_, whole_num, numerator, denominator) => {
            let decimalValue = Number(numerator) / Number(denominator);
            decimalValue = parseFloat(decimalValue.toFixed(places)) //rounding to n decimal places
            if (whole_num) {
              var tempWholeNum = whole_num.replace("-", "");
              decimalValue = Number(tempWholeNum) + decimalValue
            }
            decimalValues.push(decimalValue);
            return decimalValue.toString();
          });
    
          // Replace the decimal equivalents back into the original string in the order they were found
          let currentIndex = 0;
          let finalOutput =  output.replace(fractionRegex, () => {
            let decimalValue = decimalValues[currentIndex++];
            return decimalValue.toString();
          });
          return finalOutput;
        }
      });
    });
  };

  /**
 * Convert numbers to specified significant figures
 * @param data 2D Array of numbers to convert
 * @param significantFigures Number of significant figures
 */
  async standardizeSigFiguresConverter(data: any[][], significantFigures: string) {
    return data.map((row) => {
      return row.map((cell) => {
        if (typeof cell == 'number') {
          let numberValue = parseFloat(Number(cell).toPrecision(Number(significantFigures)));
          return numberValue;
        } else {
          let input = String(cell);
          const sigFigs = Number(significantFigures);
          const numberRegex = /(\d+\.\d+)|(\.\d+)|(\d+)|(\d+(\.\d+)?e[+-]\d+)/g;
    
          //Array to store the decimal equivalent of the fractions
          let sigFigsValues = [];
    
          // convert numbers to appropriate significant figures
          const output = input.replace(numberRegex, (match) => {
    
            let numberValue = parseFloat(Number(match).toPrecision(sigFigs));
            sigFigsValues.push(numberValue);
    
            return String(numberValue);
          });
    
          //Replace the numbers back onto the original string in the order that they appear
          let currentIndex = 0;
          let outputValue = output.replace(numberRegex, () => {
            let newValue = sigFigsValues[currentIndex++];
            return String(newValue);
          });
          return outputValue;
        }
      });
    });
  };

  /**
 * Round numbers to a specified number of decimal places
 * @param data 2D Array of numbers to convert
 * @param places number of decimal places to round
 */
  async standardizeRound(data: any[][], places: string) {
    return data.map((row) => {
      return row.map((cell) => {
        if (typeof cell == 'number') {
          //round the number to the specified decimal places
          let numberValue = parseFloat(cell.toFixed(Number(places)));
          return numberValue;
        } else {
          //convert the row to a string and round the numbers in text to the specified decimal places
          let input = String(cell);
          const roundPlaces = Number(places);
          const numberRegex = /(\d+\.\d+)|(\.\d+)|(\d+)|(\d+(\.\d+)?e[+-]\d+)/g;
          //Array to store the rounded values
          let roundedValues = [];
          // round the numbers found
          const output = input.replace(numberRegex, (match) => {
            let numberValue = parseFloat(Number(match).toFixed(roundPlaces));
            roundedValues.push(numberValue)
            return numberValue.toString()
          });
          //Replace the numbers back onto the original string in the order that they appear
          let currentIndex = 0;
          let outputValue = output.replace(numberRegex, () => {
            let newValue = roundedValues[currentIndex++];
            return newValue.toString();
          });
          return outputValue;
        }
      });
    });
  };


/**
 * Remove all the elements that occur in one list from another
 * @param data 2D Array of strings to process
 * @param tokenize tokenize inputs or not
 * @param caseSensitive set case-sensitive to true or false
 */
  async removeWords(data: any[], tokenize: boolean, caseSensitive: boolean) {
    /**
   *  Eliminate any empty strings
      Check if the elements are valid JSON arrays, if so, then add the array values to the current array
      convert all elements to string
   * @param arr Array of strings to process
   * @returns Array of strings
   */
    function _processArray(arr: any[]): string[] {
      let result: string[] = [];
      for (let item of arr) {
        // Eliminate empty strings
        if (item === '') continue;
        // Check if the item is a valid JSON array
        try {
          let parsed = JSON.parse(item);
          if (Array.isArray(parsed)) {
            // If it's a valid JSON array, add its elements to the result
            result = result.concat(parsed.map(String));
            continue;
          }
        } catch (e) {
          // Not a valid JSON array, add the item as is
          result = result.concat(String(item));
          continue;
        }
        // Convert the item to a string and add it to the result
        result.push(String(item));
      }
      return result;
    };

    /**
     * Tokenize the values to remove
     * @param to_remove Array of strings to tokenize
     * @returns Array of strings
     */
    function _tokenizeValues(to_remove: any[]): string[] {
      let temp_to_remove: any[] = [];
      to_remove.forEach(element => {
        try {
          let valueToAdd = element.split(/\s|,/);
          temp_to_remove = temp_to_remove.concat(valueToAdd);
        } catch {
          temp_to_remove = temp_to_remove.concat([element]);
        }
      });
      to_remove = temp_to_remove;
      return to_remove;
    };

    /**
     * Escape special characters in a string to be used in a regular expression
     * @param string String to escape
     * @returns escaped string
     */
    const _escapeRegexExpression = (string: string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    };

    let results: any[] = [];
    data.forEach((values: string[]) => {

      //if only one column selected, then return the original value
      if (values.length == 1) {
        results.push(values[0]);
      } else {
        //if all of the values after first element (input) are all empty then return the first element
        //As there is nothing to remove
        var valuesToCheck = values.slice(1);
        var emptyValues = valuesToCheck.every((x: any) => x == "");
        if (emptyValues) {
          results.push(values[0]);
        } else {
          var input = values[0];
          var to_remove = _processArray(values.splice(1));

          // Custom word boundary that considers a space, the start of the string, or the end of the string as a boundary
          let boundary = '(?:\\s|,|^|$)';

          if (tokenize) {
            to_remove = _tokenizeValues(to_remove);
          };

          var regexFlags = 'g';
          if (caseSensitive) {
            regexFlags = 'gi'
          }

          //remove all values in 
          for (var i = 0; i < to_remove.length; i++) {
            var findValue = _escapeRegexExpression(to_remove[i]);
            var pattern = `${boundary}${findValue}${boundary}`
            input = input.replace(new RegExp(pattern, regexFlags), " ").trim();
          };
          //convert multiple spaces to single spaces
          input = input.replaceAll(/\s{2,}/g, " ").trim();
          results.push(input);
        }
      }
    });
    return results;
  };

  /**
   * Translate the data
   * @param data array of inputs to translate
   * @param languageFrom Language code to translate from
   * @param languageTo Language Code to translate to
   * @returns 
   */
  async translate(data: any[], languageFrom: string, languageTo: string) {
    return await this.keycloak.updateToken(30).then(async () => {        
      // Make API Call
      const url = new URL(`${this.apiHost}/wrangles/translate`);
      const params = {
        responseFormat: 'array',
        sourceLanguage: languageFrom,
        targetLanguage: languageTo
      }
      url.search = new URLSearchParams(params).toString();

      return fetch(url.toString(), {
          credentials: 'include',
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' +this.keycloak.token
          },
          body: JSON.stringify(data),
      })
    })
  }

  async mapHeadings(schema: any[], potentials: any[], model_id: string) {
    return await this.keycloak.updateToken(30).then(async () => {
      const url = new URL(`${this.apiHost}/dev/map-headings`);
      const params = { model_id: model_id }
      url.search = new URLSearchParams(params).toString();

      return await fetch(url.toString(), {
        credentials: 'include',
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' +this.keycloak.token
        },
        body: JSON.stringify({
          "schema": schema,
          "potential": potentials
        })
      })
    })
  }

  async submitMap(model_id: string, map: {}) {
    return await this.keycloak.updateToken(30).then(async () => {
      const url = new URL(`${this.apiHost}/dev/submit-map`);
      const params = { model_id: model_id }
      url.search = new URLSearchParams(params).toString();

      return await fetch(url.toString(), {
        credentials: 'include',
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' +this.keycloak.token
        },
        body: JSON.stringify(map)
      })
    })
  }

  /**
   * Clear the learned map behaviour for a model
   * @param model_id ID of the model
   * @returns 
   */
  async deleteMapTraining(model_id: string) {
    return await this.keycloak.updateToken(30).then(async () => {
      const url = new URL(`${this.apiHost}/dev/clear-map`);
      const params = { model_id: model_id }
      url.search = new URLSearchParams(params).toString();

      return await fetch(url.toString(), {
        credentials: 'include',
        method: "DELETE",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this.keycloak.token
        }
      })
    })
  }

  /**
   * Get a list of users that belong to the same organization as the current user
   * @returns Array of user objects
   */
  async getOrganizationMembers() {
    return await this.keycloak.updateToken(30).then(async () => {
      const url = new URL(`${this.apiHost}/organization/members`);

      return fetch(url.toString(), {
        credentials: 'include',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this.keycloak.token
        }
      })
      .then(response => { 
        if (!response.ok){ throw response.status };
        return response
      })
      .then(response => {
        return response.json()
      })
    })
  }

  /**
   * Wrangle to lookup data against a model using either
   * key based matching or vector search
   * @param data a List of data to lookup
   * @param model_id ID of the model to lookup against
   * @param columns The columns from the model to return
   * @param settings Misc settings to pass to the backend
   * @returns 
   */
  lookup = async (data: any, model_id: string, columns: any, settings={}) => {    
    const url = new URL(`${this.apiHost}/wrangles/lookup`);
    const params = {
      model_id: model_id,
      columns: JSON.stringify(columns),
      ...settings
    }
    url.search = new URLSearchParams(params).toString();
    return fetch(url.toString(), {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this.keycloak.token
        },
      body: JSON.stringify(data)
    })
    .then(response => {
      if (!response.ok) { throw response.status }
      return response
    })
    .then(response => response.json())
  };

  /**
   * Get a list of users that belong to the same organization as the current user.
   * This will cache the results for future calls.
   * @returns Array of user objects
   */
  async getOrganizationMembersCached(){
    if (this.organizationMembers){
      return this.organizationMembers
    } else {
      this.organizationMembers = await this.getOrganizationMembers();
      return this.organizationMembers
    }
  }

  /**
   * Get details about the current user's organization(s)
   * If an organization is specified, will return details about that organization
   * otherwise, will return a list of organizations the user belongs to
   * @param id (Optional) An organization ID to get details about
   * @returns Dict or array of dicts with org id and name
   */
  async getOrganization(id?: string) {
    return await this.keycloak.updateToken(30).then(async () => {
      const url = new URL(`${this.apiHost}/organization`);
      if (id){
        url.search = new URLSearchParams({ id: id }).toString();
      }

      return fetch(url.toString(), {
        credentials: 'include',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this.keycloak.token
        }
      })
      .then(response => { 
        if (!response.ok){ throw response.status };
        return response
      })
      .then(response => {
        return response.json()
      })
    })
  }

  /**
   * Get details about the current user's organization(s).
   * This will cache the results for future calls.
   * @param id (Optional) An organization ID to get details about
   * @returns Dict or array of dicts with org id and name
   */
  async getOrganizationCached(){
    if (this.organizations){
      return this.organizations
    } else {
      this.organizations = await this.getOrganization();
      return this.organizations
    }
  }

  async askMarvin(body: any[], defaultPaths: Array<string>, searchPaths: Array<string>) {
    return await this.keycloak.updateToken(30).then(async () => {
      const url = new URL('https://marvin.wrangle.works/');
      const params = new URLSearchParams();
      if (searchPaths){
        searchPaths.forEach((path) => {
          params.append('searchPath', path);
        })
      }
      if (defaultPaths){
        defaultPaths.forEach((path) => {
          params.append('defaultPath', path);
        })
      }
      url.search = new URLSearchParams(params).toString();

      return fetch(url.toString(), {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this.keycloak.token
        },
        body: JSON.stringify(body)
      })
    })
  }
}


// Interfaces

export interface IOrganization {
  id: string;
  name: string;
}

export interface IUser {
  email: string;
  firstName: string;
  lastName: string;
}

/**
 * User roles.
 * 
 * - admin: Full access including permissions and metadata
 * - editor: Can edit the contents of wrangles
 * - viewer: Can view the contents of wrangles
 * - user: Can use Wrangles
*/
export type Role = 'admin' | 'editor' | 'viewer' | 'user';

/**
 * The purpose of a Wrangle.
 */
export type Purpose = 'classify' | 'extract' | 'standardize' | 'recipe' | 'map' | 'lookup';

/** Variants of a specific purpose of wrangle */
export type Variant = 'ai' | 'pattern' | 'key' | 'embedding'
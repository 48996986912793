import * as React from "react";
import { useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Header from "./elements/Header";

const Account = React.lazy(() => import("./routes/Account"));
const Classify = React.lazy(() => import("./routes/Classify"));
const Extract = React.lazy(() => import("./routes/Extract"));
const Format = React.lazy(() => import("./routes/Format"));
const Models = React.lazy(() => import("./routes/Models"));
const Translate = React.lazy(() => import("./routes/Translate"));
const Standardize = React.lazy(() => import("./routes/Standardize"));
const Recipes = React.lazy(() => import("./routes/Recipes"));
const MyWrangles = React.lazy(() => import("./routes/MyWrangles"));

// NOTE: Images from the manifest aren't necessarily referenced in the code,
//       so aren't automatically included in the final build. Reference here to fix.
// Can I wildcard this somehow? This seems stupid...
import "../../../assets/icons/account-16.png";
import "../../../assets/icons/account-32.png";
import "../../../assets/icons/account-80.png";

import "../../../assets/icons/classify-16.png";
import "../../../assets/icons/classify-32.png";
import "../../../assets/icons/classify-80.png";

import "../../../assets/icons/enrich-16.png";
import "../../../assets/icons/enrich-32.png";
import "../../../assets/icons/enrich-80.png";

import "../../../assets/icons/extract-16.png";
import "../../../assets/icons/extract-32.png";
import "../../../assets/icons/extract-80.png";

import "../../../assets/icons/favicon-16x16.png";
import "../../../assets/icons/favicon-32x32.png";

import "../../../assets/icons/format-16.png";
import "../../../assets/icons/format-32.png";
import "../../../assets/icons/format-80.png";

import "../../../assets/icons/help-16.png";
import "../../../assets/icons/help-32.png";
import "../../../assets/icons/help-80.png";

import "../../../assets/icons/help2-16.png";
import "../../../assets/icons/help2-32.png";
import "../../../assets/icons/help2-80.png";

import "../../../assets/icons/help-quatro-16.png";
import "../../../assets/icons/help-quatro-32.png";
import "../../../assets/icons/help-quatro-80.png";

import "../../../assets/icons/translate-16.png";
import "../../../assets/icons/translate-32.png";
import "../../../assets/icons/translate-80.png";

import "../../../assets/icons/wrangles-16.png";
import "../../../assets/icons/wrangles-32.png";
import "../../../assets/icons/wrangles-80.png";

import "../../../assets/icons/cowboy-user-16.png";
import "../../../assets/icons/cowboy-user-32.png";
import "../../../assets/icons/cowboy-user-80.png";

import "../../../assets/icons/lasso-16.png";
import "../../../assets/icons/lasso-32.png";
import "../../../assets/icons/lasso-80.png";

import "../../../assets/icons/lasso-thick-16.png";
import "../../../assets/icons/lasso-thick-32.png";
import "../../../assets/icons/lasso-thick-80.png";

import "../../../assets/icons/ww-lasso-blue-16.png";
import "../../../assets/icons/ww-lasso-blue-32.png";
import "../../../assets/icons/ww-lasso-blue-64.png";
import "../../../assets/icons/ww-lasso-blue-80.png";

// import "../../../assets/icons/ww-lasso-white-16.png";
import "../../../assets/icons/ww-lasso-white-32.png";
// import "../../../assets/icons/ww-lasso-white-64.png";
import "../../../assets/icons/ww-lasso-white-80.png";

import "../../../assets/icons/ww-16.png";
import "../../../assets/icons/ww-32.png";
import "../../../assets/icons/ww-64.png";
import "../../../assets/icons/ww-80.png";
import "../../../assets/icons/ww-128.png";

import "../../../assets/gifs/choose-wrangle.gif";
import "../../../assets/gifs/get-results.gif";
import "../../../assets/gifs/select-data.gif";
import "../../../assets/gifs/animated_wrwx.gif";

import "../../../assets/logos/wrangleworks-220x36.png"
/* global console, Excel  */


export interface AppProps {
  isOfficeInitialized: boolean;
}

const App: React.FunctionComponent<AppProps> = (props) => {
  
  const[loading, ] = useState(false);
  const [searchText, setSearchText] = React.useState("");
  const headerHeight = 76;
  const logoURL = "/assets/icons/ww-lasso-white-32.png";

  useEffect(() => {}, []); // is this needed?
  // class component had componentDidMount() {}
  
  const { isOfficeInitialized } = props
  
  if (!isOfficeInitialized) {
    return (
      <p>Please sideload your addin to see app body.</p>
    );
  }
  
  return (
    <>
      { loading
        ? '' : 
          <div className="ms-welcome">
            <BrowserRouter>
              <React.Suspense fallback={<div></div>}>
                <Switch>
                    <Route
                      path="/classify.html"
                      render={() => (
                        <>
                          <Header logo={logoURL} title={'Classify'} />
                          <Classify
                            stickyHeight={headerHeight}
                            searchText={searchText}
                            setSearchText={setSearchText}
                          />
                        </>
                      )}
                    />
                    <Route
                      path="/extract.html"
                      render={() => (
                        <>
                          <Header logo={logoURL} title={'Extract'} />
                          <Extract
                            stickyHeight={headerHeight}
                            searchText={searchText}
                            setSearchText={setSearchText}
                          />
                        </>
                      )}
                    />
                    <Route
                      path="/format.html"
                      render={() => (
                        <>
                          <Header logo={logoURL} title={'Format'} />
                          <Format />
                        </>
                      )}
                    />
                    <Route
                      path="/models.html"
                      render={() => (
                        <>
                          <Header logo={logoURL} title={'My Wrangles'} />
                          <Models
                            stickyHeight={headerHeight}
                            searchText={searchText}
                            setSearchText={setSearchText}
                          />
                        </>
                      )}
                    />
                    <Route
                      path="/translate.html"
                      render={() => (
                        <>
                          <Header logo={logoURL} title='Translate' />
                          <Translate />
                        </>
                      )}
                    />
                    <Route
                      path="/standardize.html"
                      render={() => (
                        <>
                          <Header logo={logoURL} title={'Standardize'} />
                          <Standardize
                            stickyHeight={headerHeight}
                            searchText={searchText}
                            setSearchText={setSearchText}
                          />
                        </>
                      )}
                    />
                    <Route
                      path="/recipes.html"
                      render={() => (
                        <>
                          <Header logo={logoURL} title={'Recipes'} />
                          <Recipes
                            stickyHeight={headerHeight}
                            searchText={searchText}
                            setSearchText={setSearchText}
                          />
                        </>
                      )}
                    />
                    <Route path="/account.html" render={() => (<Account />)}/>
                    <Route
                      path="/wrangles.html"
                      render={() => (
                        <MyWrangles
                          stickyHeight={headerHeight}
                          searchText={searchText}
                          setSearchText={setSearchText}
                        />
                      )}
                    />
                </Switch>
              </React.Suspense>
            </BrowserRouter>
          </div>
      }
    </>
  );

};

export default App;
import App from "./components/App";
import FirstRun from "./components/elements/FirstRun";
import { AppContainer } from "react-hot-loader";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { ThemeProvider } from "@fluentui/react";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { WranglesProvider } from "../utils/api";


// Find if this is the user's first time opening the add-in
var shownFirstRun = 'false';
try {
  shownFirstRun = localStorage.getItem('wrwx/wrangles/shownFirstRun');
}
catch {
  // If this fails and local storage isn't available for some reason
  // then skip showing first run page to avoid repeated showing.
  shownFirstRun = 'true';
}

const placeholder = <img
  id="wrangleworks-loading"
  src="/assets/gifs/animated_wrwx.gif"
  title="test"
  style={{
    width:'250px',
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  }}
/>
/* global document, Office, module, require */

initializeIcons();

let isOfficeInitialized = false;

const render = (Component) => {
  ReactDOM.render(
    <AppContainer>
      <ThemeProvider>
        <WranglesProvider
          init={{
            onLoad: 'login-required',
            silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html"
          }}
          config={{
            url: 'https://sso.wrangle.works/auth',
            realm: 'wrwx',
            clientId: 'wranglesXL'
          }}
          placeholder={placeholder}
        >
          <Component isOfficeInitialized={isOfficeInitialized} />
        </WranglesProvider>
      </ThemeProvider>
    </AppContainer>,
    document.getElementById("container")
  );
};

const renderFirstRun = () => {
  ReactDOM.render(
    <AppContainer>
      <ThemeProvider>
        <FirstRun></FirstRun>
      </ThemeProvider>
    </AppContainer>,
    document.getElementById("container")
  );
}

/* Render application after Office initializes */
Office.initialize = () => {
  isOfficeInitialized = true;

  if (shownFirstRun == 'true'){
    render(App);
  }
  else {
    renderFirstRun();
  }
};

if ((module as any).hot) {
  (module as any).hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    render(NextApp);
  });
}
import * as React from 'react';
import {
  Icon,
  Stack,
  CommandBarButton,
  IButtonStyles,
  TooltipHost,
  TooltipDelay,
} from '@fluentui/react';
import { Link } from "react-router-dom";
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { useBoolean } from '@fluentui/react-hooks';
import { useWrangles } from '../../../utils/api';

export const NavMenu: React.FunctionComponent = () => {
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
  const roles = useWrangles().token().resource_access?.wranglesXL?.roles || []

  return (
    <div>
      <div
        style={{position: "absolute", left: "20px", height: "30px", top: "30px", fontSize: "20px", cursor: 'pointer'}}
        onClick={openPanel}
      >
        {<Icon iconName='GlobalNavButton'></Icon>}
      </div>
      <Panel
        headerText="Wrangles"
        styles={{headerText: {color: '#0075c9'}}}
        isLightDismiss
        isOpen={isOpen}
        onDismiss={dismissPanel}
        // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
        closeButtonAriaLabel="Close"
        type={PanelType.smallFixedNear}
      >
        <br></br>
        <Stack>
          <TooltipHost
            content="Train and Manage your own custom Wrangles."
            id="tooltipCase"
            calloutProps={{ gapSpace: 0 }}
            styles={{ root: { display: 'inline-block' } }}
            delay={TooltipDelay.long}
          >
            <Link to="/models.html">
              <CommandBarButton
                styles={commandMenuButtonStyles}
                iconProps={{ iconName: "GroupedList" }}
                text="My Wrangles"
                onClick={dismissPanel}
                >
              </CommandBarButton>
            </Link>
          </TooltipHost>
        </Stack>

        {
          roles.includes('beta') &&
          <Stack>
            <TooltipHost
              content="Automate sequences of Wrangles with recipes."
              id="tooltipCase"
              calloutProps={{ gapSpace: 0 }}
              styles={{ root: { display: 'inline-block' } }}
              delay={TooltipDelay.long}
            >
                <Link to="/recipes.html">
                  <CommandBarButton
                    styles={commandMenuButtonStyles}
                    iconProps={{ iconName: "Chopsticks" }}
                    text="Recipes"
                    onClick={dismissPanel} 
                      >
                  </CommandBarButton>
                </Link>
              </TooltipHost>
          </Stack>
        }

        <Stack>
          <TooltipHost
            content="Use your custom classification Wrangles to predict which categories items belong to."
            id="tooltipCase"
            calloutProps={{ gapSpace: 0 }}
            styles={{ root: { display: 'inline-block' } }}
            delay={TooltipDelay.long}
          >
            <Link to="/classify.html">
              <CommandBarButton
                styles={commandMenuButtonStyles}
                iconProps={{ iconName: "DistributeDown" }}
                text="Classify"
                onClick={dismissPanel}
                 >
              </CommandBarButton>
            </Link>
          </TooltipHost>
        </Stack>

        <Stack>
          <TooltipHost
            content="Extract meaningful information from unstructured text. Use our stock Wrangles or train your own."
            id="tooltipCase"
            calloutProps={{ gapSpace: 0 }}
            styles={{ root: { display: 'inline-block' } }}
            delay={TooltipDelay.long}
          >
            <Link to="/extract.html">
              <CommandBarButton
                styles={commandMenuButtonStyles}
                iconProps={{ iconName: "Zoom" }}
                text="Extract"
                onClick={dismissPanel}
                 >
              </CommandBarButton>
            </Link>
          </TooltipHost>
        </Stack>

        <Stack>
         <TooltipHost
            content="Tools for formatting data such as changing case, trimming, merging and splitting."
            id="tooltipCase"
            calloutProps={{ gapSpace: 0 }}
            styles={{ root: { display: 'inline-block' } }}
            delay={TooltipDelay.long}
          >
            <Link to="/format.html">
              <CommandBarButton
                styles={commandMenuButtonStyles}
                iconProps={{ iconName: "LightningBolt" }}
                text="Format"
                onClick={dismissPanel}
                 >
              </CommandBarButton>
            </Link>
          </TooltipHost>
        </Stack>

        <Stack>
          <TooltipHost
            content="Create Wrangles to standardize text data."
            id="tooltipCase"
            calloutProps={{ gapSpace: 0 }}
            styles={{ root: { display: 'inline-block' } }}
            delay={TooltipDelay.long}
          >
            <Link to="/standardize.html">
              <CommandBarButton
                styles={commandMenuButtonStyles}
                iconProps={{ iconName: "GUID" }}
                text="Standardize"
                onClick={dismissPanel}
                 >
              </CommandBarButton>
            </Link>
          </TooltipHost>
        </Stack>

        <Stack>
          <TooltipHost
            content="Translates the input into the chosen language."
            id="tooltipCase"
            calloutProps={{ gapSpace: 0 }}
            styles={{ root: { display: 'inline-block' } }}
            delay={TooltipDelay.long}
          >
            <Link to="/translate.html">
              <CommandBarButton
                styles={commandMenuButtonStyles}
                iconProps={{ iconName: "Translate" }}
                text="Translate"
                onClick={dismissPanel}
                 >
              </CommandBarButton>
            </Link>
          </TooltipHost>
        </Stack>
      </Panel>
    </div>
  );
};


//Styling
const commandMenuButtonStyles: Partial<IButtonStyles> = { root: { width: 220, height: 35, textAlign: 'left' } };
